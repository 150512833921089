import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/video-react/dist/video-react.css';
import "react-datetime/css/react-datetime.css";
import Header from './components/header';
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './components/login';
import Body from './components/body';
import Footer from './components/footer';
import LessonPage from './components/lessonPage';
import ModuleTest from './components/module';
import Cart from './components/cart';
import Checkout from './components/checkout';
import Thankyou from './components/thankyouPage';
import AccountPage from './components/accountPage';
import AboutUsPage from './components/aboutPage';
import ProductPage from './components/productPage/productPageMainComponent';
import MyCoursePageComponent from './components/my-course-page/my-course-page-main';
import { getSession } from './services/bffServices/getSessionInfo';
import TextMainPage from './components/textbook/textbook';
import ModuleComponent from './components/textbook/module';
import CheckoutMainComponent from './components/checkout/checkoutMainComponent';
import ErrorPageComponent from './components/error-page/errorPageComponent';
import LandingPageComponent from './components/catalogue/landingPageComponent';
import CataloguePageComponent from './components/catalogue/catalogueComponent';
import ContactUsPageMainComponent from './components/contactUsPage/contactUsMainComponent';
import ContactTexasOrderFormComponent from './components/contactUsPage/contactTexasOrderFormComponent';
import TrialMainPageMainComponent from './components/trialCourse/trialMainPageComponent';
import CourseBuilderMainComponenet from './components/custom-course-builder/main';
import ResetPasswordComponent from './components/account-dashboard/reset-password';
import BookstoreComponent from './components/catalogue/bookstore/bookstoreComponent';
import SubjectsPageComponent from './components/catalogue/subjectsPageComponent';
import NotFoundComponent from './components/error-page/notFoundComponent';
import PrivacyPolicyTCMainComponent from './components/privacy-and-tc/privacytcMainComponent';
import { initGA, logPageView, setUserId, logTimeOnPage } from '../src/services/googleAnalytics/analytics';
import {delay} from '../src/utls/lib';
require('dotenv').config()

function App() {
  const queryParams = new URLSearchParams(window.location.search);
  const cookieExists = document.cookie.split(";").some((item) => item.trim().startsWith("sessionID="));

  var session = async () => {

    var currentSessionId = "";

    if (queryParams.get('lti')) {
      await sessionStorage.setItem("isLTI", "Y");
      await sessionStorage.setItem("sessionID", queryParams.get('lti'));

      if (queryParams.get('l') != "f") {
        await sessionStorage.setItem('existingUser', "Y")
        if (queryParams.get("redirectType") === "CHAPTER") {
          sessionStorage.setItem("ltiChapter", queryParams.get("redirectToContent"));
          window.location.href = "/account?ltiChapter=true";
        } else {
          window.location.href = "/account";
        }
      } else {


        let productParam = queryParams.get("p");

        if (!productParam || productParam === "undefined" || productParam === "0" || productParam === "null") {
          window.location.href = "/buy/class";
        } else {
          window.location.href = "product?id=" + productParam;
        }

          //Debugger URLs
          //http://localhost:3000/?lti=c020bcc1-fb59-46fa-ba7c-e3948aee0a97&l=f&p=undefined
          //http://localhost:3000/?lti=c020bcc1-fb59-46fa-ba7c-e3948aee0a97&l=f&p=0
          //http://localhost:3000/?lti=c020bcc1-fb59-46fa-ba7c-e3948aee0a97&l=f&p=63aa3264d966ed1cbb5f0dbc
          //http://localhost:3000/?lti=c020bcc1-fb59-46fa-ba7c-e3948aee0a97&l=f

      }

    } else {

      currentSessionId = sessionStorage.getItem("sessionID");
    }
    
    if (currentSessionId == null || currentSessionId == 'error' || currentSessionId == "undefined" ) {
      try {
        if (cookieExists) {
          const cookieValue2 = document.cookie.replace(/(?:(?:^|.*;\s*)sessionID\s*\=\s*([^;]*).*$)|^.*$/, "$1");
          sessionStorage.setItem("sessionID", cookieValue2);
        } else {
          var sessionInfo = await getSession();
          await delay(800);//applying as mobile was not abiding by the await above. Debug and refactor later JMD
          sessionStorage.setItem("sessionID", sessionInfo?.sessionID);
          return sessionInfo;
        }

      } catch (err) {
        sessionStorage.setItem("sessionID", "error");
        window.location.href = "/error?errMsg=" + err + JSON.stringify(sessionInfo);
      }
    }

    if (currentSessionId != undefined || currentSessionId != null) {
      return sessionStorage.getItem("sessionID");
    }

  }

  session();
  useEffect(async () => {
    initGA();
    if (cookieExists) {
      const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)sessionID\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      sessionStorage.setItem("sessionID", cookieValue);
      const needRefresh = sessionStorage.getItem("refresh");
      if (needRefresh == null) {
        sessionStorage.setItem("refresh", true);
        window.location.reload();
      }
    }
  }, [cookieExists])

  return (
    <div onContextMenu={(e) => e.preventDefault()} className="App relative min-h-screen" style={{ hieght: "1000px" }}>
      {session}
      <link href='https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300italic,400italic,500,500italic,700,700italic,900italic,900' rel='stylesheet' type='text/css' />
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css" />

      <Header />
      {/* <AlertSB /> */}
      <main>
      <Router>
        <Routes>
          <Route exact path="/" element={<Body />}>
          </Route>
          <Route exact path="/login" element={<Login />}>
          </Route>
          <Route exact path="/account" element={<AccountPage />}>
          </Route>
          <Route exact path="/buy" element={<LandingPageComponent />}>
          </Route>
          <Route exact path="/buy/class" element={<CataloguePageComponent filter="class" />}>
          </Route>
          <Route exact path="/lessontest" element={<LessonPage />}>
          </Route>
          <Route exact path="/module/:id" element={<ModuleTest />}>
          </Route>
          <Route exact path="/cart" element={<Cart />}>
          </Route>
          <Route exact path="/checkout" element={<Checkout />}>
          </Route>
          <Route exact path="/checkout-new" element={<CheckoutMainComponent />}>
          </Route>
          <Route exact path="/thankyou" element={<Thankyou />}>
          </Route>
          <Route exact path="/product" element={<ProductPage />}>
          </Route>
          <Route exact path="/my-textbooks" element={<MyCoursePageComponent />}>
          </Route>
          <Route exact path="/textbook" element={<TextMainPage />}>
          </Route>
          <Route exact path="/textbook/module" element={<ModuleComponent />}>
          </Route>
          <Route exact path="/error" element={<ErrorPageComponent />}>
          </Route>
          <Route exact path="/contact-us" element={<ContactUsPageMainComponent />}>
          </Route>
          <Route exact path='/about-us' element={<AboutUsPage></AboutUsPage>}>
          </Route>
          <Route exact path="/k-12/order" element={<ContactTexasOrderFormComponent />}>
          </Route>
          <Route exact path="/trial" element={<TrialMainPageMainComponent />}>
          </Route>
          <Route exact path="/custom-course-builder" element={<CourseBuilderMainComponenet />}>
          </Route>
          <Route exact path="/passwordReset/:id" element={<ResetPasswordComponent />}>
          </Route>
          <Route exact path="/bookstore" element={<BookstoreComponent />}>
          </Route>
          <Route exact path="/subjects/highschool"  element={<SubjectsPageComponent/>}>
          </Route>
          <Route exact path="/subjects/highered"  element={<SubjectsPageComponent/>}>
          </Route>
          <Route exact path="/subjects/highered/upper-year-biology"  element={<SubjectsPageComponent/>}>
          </Route>
          <Route exact path="/subjects/highered/first-year-biology"  element={<SubjectsPageComponent/>}>
          </Route>
          <Route exact path="/subjects" element={<SubjectsPageComponent/>}>
          </Route>
          <Route exact path="/privacy-and-terms" element={<PrivacyPolicyTCMainComponent />}>
          </Route>
          <Route exact path="/not-found" element={<NotFoundComponent />}>
          </Route>
          <Route exact path="*" element={<NotFoundComponent />}>
          </Route>
        </Routes>
      </Router>
      </main>
      {window.location.pathname !== '/not-found' && 
      window.location.pathname !== '/buy/class' && 
      window.location.pathname !== '/checkout' && 
      window.location.pathname !== '/buy' && 
      window.location.pathname !== '/buy/individual' && 
      window.location.pathname !== '/thankyou' && 
      window.location.pathname !== '/bookstore' && 

      <Footer />}

    </div >
  );
}

export default App;