import Table from 'react-bootstrap/Table'
import React, { useState, useEffect, useRef } from "react";
import Modal from 'react-bootstrap/Modal'
import Alert from 'react-bootstrap/Alert';
import {getOrderByUserID} from '../../services/bffServices/getOrderDetails';
import OrderModelComponent from '../account-dashboard/orderModelv2';


function OrderListComponent(userId){
    const [showOrderInfo, setShowOrderInfo] = useState(false);
    const [orderList, setOrderList] = useState([{}]);
    const [orderTotal, setOrderTotal] = useState();
    const [orderPriceBreakdown, setorderPriceBreakdown] = useState();
    const [orderProductInfo, setOrderProductInfo] = useState();
    ///need to make one for each bit I need.. not sure how to do it for many orders :-|

    var orderListPayload = async() => {
         var blah = await getOrderByUserID();
        //  console.log(JSON.stringify(blah));
         if(blah != null || blah != undefined){
          setOrderTotal(blah[0]?.orderTotal?.Total);
          setorderPriceBreakdown(blah[0]?.orderTotal);
          setOrderProductInfo(blah[0]?.product);
          setOrderList(blah);
          return blah;
         }    
    }

    useEffect(async ()=>{
        var orderListReponse = await orderListPayload();

          },[])

//To DO::: 
// Line 37:15:  Effect callbacks are synchronous to prevent race conditions. Put the async function inside:

// useEffect(() => {
//   async function fetchData() {
//     // You can await here
//     const response = await MyAPI.getData(someId);
//     // ...
//   }
//   fetchData();
// }, [someId]); // Or [] if effect doesn't need props or state

    return (
    <>
        <Table striped bordered hover>
        <thead>
            <tr>
            <th scope="col">Order #</th>
            <th scope="col">Date</th>
            <th scope="col">Total</th>
            <th scope="col">Status</th>
            </tr>
        </thead>
        <tbody>
            <tr>
            <td><Alert.Link onClick={() => setShowOrderInfo(true)}>{orderList[0].id}</Alert.Link></td>
            <td>{orderList[0].date}</td>
            <td>${orderTotal}</td>
            
            <td>{orderList[0].orderStatus}</td>
            </tr>
        </tbody>
        </Table>

        <Modal
        show={showOrderInfo}
        onHide={() => setShowOrderInfo(false)}
        dialogClassName="modal-90w"
        size="lg"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <OrderModelComponent orderProductInfo={orderProductInfo} orderPriceBreakdown={orderPriceBreakdown} orderList={orderList[0]}/>
      </Modal>
    </>
    );
}

export default OrderListComponent;